import {
  signInWithEmailAndPassword,
  confirmPasswordReset,
} from "firebase/auth";
import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/authHook.js";
import { auth } from "../../index.js";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const ConfirmPasswordReset = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

  const [PW, setPW] = useState("");
  const [verifyPW, setVerifyPW] = useState("");

  const [passwordsValid, setPasswordsValid] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setPasswordsValid(passwordRegex.test(PW) && PW && PW === verifyPW);
  }, [PW, verifyPW]);

  const handlePasswordChange = (e) => {
    setPW(e.target.value);
  };

  const handleVerifyPW = (e) => {
    setVerifyPW(e.target.value);
  };

  const updatePassword = () => {
    if (passwordsValid)
      confirmPasswordReset(auth, searchParams.get("oobCode"), PW)
        .then(() => {
          handleSignIn();
        })
        .catch((error) => {
          alert(error);
        });
  };

  const handleSignIn = () => {
    signInWithEmailAndPassword(auth, searchParams.get("email"), PW)
      .then((userCredential) => {
        const user = userCredential.user;
        navigate("/");
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "80vh",
        width: "400px",
        maxWidth: "80vw",
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: "rgb(240, 240, 240)",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "20px",
        }}
      >
        <input
          type="password"
          placeholder="Password..."
          onChange={handlePasswordChange}
        />
        <input
          type="password"
          placeholder="Verify Password..."
          onChange={handleVerifyPW}
        />
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            //width: "200px",
            marginBottom: "25px",
          }}
          disabled={!passwordsValid}
          onClick={updatePassword}
        >
          <h2
            style={{
              padding: "2px 5px",
            }}
          >
            Reset Password
          </h2>
        </button>
      </div>
    </div>
  );
};
