import { useState, useEffect } from "react";
import styles from "../../css/styles.module.css";

export default function ButtonPurchase(props) {
  const [customAmount, setCustomAmount] = useState(props.creditOptions.amount);

  const handleDownloadClick = () => {
    window.open(
      "https://download1075.mediafire.com/edpj8lnh3ttgIFifRiJWJeZuXGnCO9GdJ-ugPR90S-wPLdzdijVbLBTG-bQe9ekSoMc5kRCOOmNO0OHWTmYWFjNaEfNZ7vw-yXfJ3UvVpQfXP4EX_-kg1FuoyDqK-mIyMjpPksoYfKjHcPkoQVKXiD8oD36F4HC4gXG0HcCpHMgYGQ/j7e4xhf1vcxh1u5/Beards.rar"
    );
  };

  const handleSubmit = (e) => {
    if (e < 5000) {
      setCustomAmount(5000);
    } else {
      setCustomAmount(e);
    }
  };

  const getCost = () => {
    switch (customAmount) {
      case 100:
        return 3;
        break;
      case 1000:
        return 20;
        break;
      default:
        return Math.round(customAmount * 0.016);
        break;
    }
  };

  return (
    <div
      className={styles.creditTab}
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        margin: "10px",
        // width: "260px",
      }}
    >
      <button
        onClick={() => {
          props.onClick(customAmount);
        }}
        className={styles.buttonPurchase}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "160px",
          maxWidth: "80vw",
          marginBottom: "20px",
          padding: "20px",
          alignItems: "flex-start",
        }}
      >
        <h1
          style={{
            display: "flex",
            flexDirection: "row",
            //height: "40px",
            //backgroundColor: "black",
          }}
        >
          <img
            style={{
              width: "30px",
              height: "30px",
              paddingRight: props.creditOptions.input ? "15px" : "10px",
            }}
            src="https://i.imgur.com/UaJiKBh.png"
          />
          {props.creditOptions.input && (
            <select
              style={{
                width: "100%",
                fontSize: "x-large",
                fontWeight: "600",
                borderRadius: "10px",
                padding: "3px",
                color: "rgba(60,60,60,1)",
              }}
              value={customAmount}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                handleSubmit(e.target.value);
              }}
            >
              {[5000, 10000, 15000, 20000, 25000, 30000].map((number, i) => (
                <option key={number} value={number}>
                  {number}
                </option>
              ))}
            </select>
          )}
          {!props.creditOptions.input && props.creditOptions.amount}
        </h1>
        <div
          style={{
            border: "1px solid rgba(128,128,128,0.5)",
            margin: "auto",
            width: "100%",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <h1 style={{}}>${getCost()}</h1>
        </div>
      </button>
      {Object.values(props.creditOptions.text).map((d, i) => {
        return (
          <h3 key={i} style={{ paddingLeft: "10px" }}>
            {d}
          </h3>
        );
      })}
    </div>
  );
}
