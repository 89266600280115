import { where, query, collection, onSnapshot } from "firebase/firestore";
import { db } from "../../index.js";
import { useState, useEffect } from "react";
import styles from "../../css/styles.module.css";

import coin1 from "../../assets/UG_Thumb.png";

export default function Profile(props) {
  const [credit, setCredit] = useState(0);

  useEffect(() => {
    const docRef = query(
      collection(db, "key-data"),
      where("owner", "==", props.uid)
    );
    const unsub = onSnapshot(docRef, (d) => {
      d.forEach((b) => {
        setCredit(b.data().credit);
      });
    });
    return unsub;
  }, []);

  return (
    <h2
      className={styles.mainDiv}
      style={{
        position: "fixed",
        top: "20px",
        right: "100px",
        height: "50px",
        //width: "400px",
        paddingRight: "15px",
        paddingLeft: "10px",
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 6px",
        fontWeight: "bold",
      }}
    >
      <img
        style={{
          width: "30px",
          paddingRight: "10px",
        }}
        src="https://i.imgur.com/UaJiKBh.png"
      ></img>{" "}
      {Math.round(credit / 2)}
    </h2>
  );
}
