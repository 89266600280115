import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/authHook.js";
import { auth } from "../../index.js";
import { useNavigate } from "react-router-dom";
import Home from "../tools/home.js";

export const Auth = () => {
  const authed = useAuth(false);

  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

  const [email, setEmail] = useState("");
  const [PW, setPW] = useState("");
  const [verifyPW, setVerifyPW] = useState("");
  const [registering, setRegistering] = useState(false);

  const [canLogin, setCanLogin] = useState(false);
  const [canRegister, setCanRegister] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (canLogin && passwordRegex.test(verifyPW) && verifyPW && PW === verifyPW)
      setCanRegister(true);
    else setCanRegister(false);
  }, [verifyPW]);

  useEffect(() => {
    if (email && PW) setCanLogin(true);
    else setCanLogin(false);
  }, [email, PW]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPW(e.target.value);
  };

  const handleVerifyPW = (e) => {
    setVerifyPW(e.target.value);
  };

  const handleSignUp = () => {
    if (registering) {
      createUserWithEmailAndPassword(auth, email, PW)
        .then((userCredential) => {
          const user = userCredential.user;
          navigate("/");
        })
        .catch((error) => {
          alert(error);
        });
    } else setRegistering(true);
  };

  const handleSignIn = () => {
    signInWithEmailAndPassword(auth, email, PW)
      .then((userCredential) => {
        const user = userCredential.user;
        navigate("/");
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    if (authed) {
      navigate("/");
    }
  }, [authed]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "80vh",
        width: "400px",
        maxWidth: "80vw",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Home />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <input
          type="email"
          placeholder="Email..."
          onChange={handleEmailChange}
        />
        <input
          type="password"
          placeholder="Password..."
          onChange={handlePasswordChange}
        />
        {registering ? (
          <input
            type="password"
            placeholder="Verify Password..."
            onChange={handleVerifyPW}
          />
        ) : (
          <div />
        )}
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          style={{
            marginBottom: "30px",
            marginLeft: "10px",
            fontWeight: "bold",
            color: "gray",
            fontSize: "0.8em",
            cursor: "pointer",
            marginRight: "auto",
          }}
          onClick={() => {
            navigate("/passwordReset?email=" + email);
          }}
        >
          <h2>Reset Password</h2>
        </a>
        <button
          style={{
            width: "150px",
            marginBottom: "15px",
          }}
          onClick={handleSignIn}
          disabled={!canLogin}
        >
          <h2
            style={{
              padding: "2px 5px",
            }}
          >
            Sign In
          </h2>
        </button>
        <div
          style={{
            marginBottom: "15px",
            fontWeight: "600",
          }}
        >
          <h3>or</h3>
        </div>
        <button
          style={{
            width: "150px",
          }}
          onClick={handleSignUp}
          disabled={!canRegister && registering}
        >
          <h2
            style={{
              padding: "2px 5px",
            }}
          >
            Register
          </h2>
        </button>
      </div>
    </div>
  );
};
