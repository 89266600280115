import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";
import Spinner from "../../assets/loading.gif";
import styles from "../../css/styles.module.css";

function Payment(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    fetch(
      "https://us-central1-ugengine.cloudfunctions.net/app/stripe/config"
    ).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    setClientSecret("");
    if (!props.amount || !props.userID) return;
    fetch(
      "https://us-central1-ugengine.cloudfunctions.net/app/stripe/buyCredit",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount: props.amount, userID: props.userID }),
      }
    ).then(async (r) => {
      const { clientSecret } = await r.json();
      setClientSecret(clientSecret);
    });
  }, [props]);

  return (
    <div style={{ backgroundColor: "rgba(0,0,0,0)", maxHeight: "60vh" }}>
      {!stripePromise && (
        <div className={styles.stripeDiv}>
          <img src={Spinner} style={{ width: "50px" }} />
        </div>
      )}
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm
            style={{ backgroundColor: "black", maxHeight: "60vh" }}
            amount={props.amount}
            onFinished={props.onFinished}
          />
        </Elements>
      )}
    </div>
  );
}

export default Payment;
