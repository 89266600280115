import { sendPasswordResetEmail } from "firebase/auth";
import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/authHook.js";
import { auth } from "../../index.js";
import { useSearchParams } from "react-router-dom";
import Home from "../tools/home.js";

export const PasswordReset = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState("");

  const [canLogin, setCanLogin] = useState(false);

  const [linkSent, setLinkSent] = useState(false);

  useEffect(() => {
    setEmail(searchParams.get("email"));
  }, []);

  useEffect(() => {
    setCanLogin(email);
  }, [email]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const requestPasswordChange = () => {
    if (auth && email) {
      sendPasswordResetEmail(auth, email);
      setLinkSent(true);
    }
  };

  if (linkSent) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Reset link sent to the provided email.</h1>
        <Home />
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "80vh",
          width: "400px",
          maxWidth: "80vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Home />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          <input
            placeholder="Email..."
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              marginBottom: "25px",
            }}
            disabled={!canLogin}
            onClick={requestPasswordChange}
          >
            Send Link
          </button>
        </div>
      </div>
    );
  }
};
