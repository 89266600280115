import "./css/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import MainApp from "./App";
import reportWebVitals from "./reportWebVitals";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBNBnrrbXgBeRGSSA2ZryDVEZAtJbUp36k",
  authDomain: "ugengine.firebaseapp.com",
  projectId: "ugengine",
  storageBucket: "ugengine.appspot.com",
  messagingSenderId: "426023615128",
  appId: "1:426023615128:web:6c3fc33e4e9341d45d6eed",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MainApp />
  </React.StrictMode>
);

reportWebVitals();
