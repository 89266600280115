import styles from "../../css/styles.module.css";

export default function Options(props) {
  return (
    <div
      className={styles.mainDiv}
      style={{
        position: "fixed",
        top: "90px",
        right: "20px",
        width: "300px",
        maxWidth: "90vw",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 6px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "15px",
          }}
        >
          <h2
            style={{
              borderRadius: "0px",
              margin: "auto",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            API Key
          </h2>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              type="password"
              placeholder="..."
              value={props.apiKey}
              readOnly={true}
              style={{ marginRight: "20px" }}
            ></input>
            <button
              onClick={() => {
                navigator.clipboard.writeText(props.apiKey);
              }}
              style={{ padding: "10px" }}
            >
              <h2>Copy</h2>
            </button>
          </div>
        </div>
        <div style={{ padding: "1px" }}></div>
        <button
          style={{ borderRadius: "0px", height: "100%", padding: "15px" }}
          onClick={() => {
            props.handleSignOut();
          }}
        >
          <h2>Log out</h2>
        </button>
      </div>
    </div>
  );
}
