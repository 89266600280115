import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import ButtonPurchase from "../buttons/buttonPurchase";
import Payment from "../stripe/payment.js";
import styles from "../../css/styles.module.css";

export default function CreditTab(props) {
  const [amount, setAmount] = useState(400);
  const [customAmount, setCustomAmount] = useState(5000);
  const [paying, setPaying] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setPaying(false);
  }, [props]);

  const togglePaying = (a) => {
    if (props.authed) {
      if (paying) {
        setPaying(false);
      } else {
        setAmount(a);
        setPaying(true);
      }
    } else {
      navigate("/auth");
    }
  };

  const creditOptions = {
    low: {
      amount: 100,
      cost: 3,
      text: [
        "Enough for...",
        "~3000 lines of TTS, or...",
        "~5000 generated sentences",
      ],
      input: false,
    },
    mid: {
      amount: 1000,
      cost: 20,
      text: [],
      input: false,
    },
    high: {
      amount: 5000,
      cost: 80,
      text: ["Enter your own amount"],
      input: true,
    },
  };

  return (
    <div
      style={{
        display: "flex",
        margin: "60px",
        minWidth: "90vw",
        flexWrap: "wrap",
        //backgroundColor: "red",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          //backgroundColor: "red",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <h1
            style={{
              paddingLeft: "30px",
              paddingBottom: "10px",
              fontSize: "xxx-large",
            }}
          >
            Buy credit
          </h1>
          <h3
            style={{
              width: "300px",
              maxWidth: "80vw",
              paddingLeft: "30px",
              paddingBottom: "10px",
            }}
          >
            Credit can be used for generations in the app or through the API
          </h3>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {Object.values(creditOptions).map((d, i) => {
            return (
              <ButtonPurchase
                key={i}
                creditOptions={d}
                onClick={(a) => {
                  togglePaying(a);
                }}
              />
            );
          })}
        </div>
      </div>
      {props.authed && paying && (
        <div>
          <Payment
            amount={amount}
            userID={props.userID}
            onFinished={() => setPaying(false)}
          />
        </div>
      )}
    </div>
  );
}
