import { Navigate, useNavigate } from "react-router-dom";
import styles from "../../css/styles.module.css";

export default function Home() {
  const navigate = useNavigate();

  return (
    <button
      className={styles.optionsButton}
      style={{
        position: "fixed",
        top: "20px",
        right: "20px",
        height: "50px",
        width: "50px",
      }}
      onClick={() => {
        navigate("/");
      }}
    >
      <span>
        <img
          style={{
            height: "30px",
            width: "30px",
            margin: "auto",
            display: "block",
          }}
          src="https://i.imgur.com/5kMv3ih.png"
        ></img>
      </span>
    </button>
  );
}
