import styles from "../../css/styles.module.css";

export default function ButtonDownload(props) {
  const handleDownloadClick = () => {
    window.open("https://www.mediafire.com/folder/s3xt5fe5cr362/UG+Engine");
  };

  return (
    <button onClick={handleDownloadClick} className={styles.buttonDownload}>
      <span>
        <img
          style={{
            paddingRight: "20px",
            height: "50px",
            width: "50px",
            margin: "auto",
            display: "block",
          }}
          src="https://i.imgur.com/8n2sDky.png"
        ></img>
      </span>
      Download
    </button>
  );
}
