import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../index.js";
import { useAuth } from "../../hooks/authHook.js";
import { auth } from "../../index.js";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import styles from "../../css/styles.module.css";
import Ribbon from "../../assets/Ribbon.png";

import News from "../tabs/news.js";
import Documentation from "../tabs/documentation.js";
import Downloads from "../tabs/downloads.js";
import Contact from "../tabs/contact.js";
import ButtonDownload from "../buttons/buttonDownload.js";
import CreditTab from "../tabs/creditTab.js";

import Profile from "../tools/profile.js";
import Options from "../tools/options.js";

export const Dashboard = () => {
  const authed = useAuth(false);
  const [amount, setAmount] = useState(400);
  const [paying, setPaying] = useState(false);
  const [tab, setTab] = useState("News");
  const [options, setOptions] = useState(false);
  const [apiKey, setApiKey] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (db && authed) {
      const docRef = doc(db, "user-data", auth.currentUser.uid);
      const unsub = onSnapshot(docRef, (d) => {
        setApiKey(d.data().activeKey);
      });
      console.log(apiKey);
      return unsub;
    }
  }, [authed]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/auth");
    } catch (error) {
      alert(error);
    }
  };

  const toggleOptions = () => {
    setOptions(!options);
  };

  function getTab(_tab) {
    switch (_tab) {
      case "News":
        return <News />;
      case "Downloads":
        return <Downloads />;
      case "Documentation":
        return <ButtonDownload />;
      case "Contact":
        return <Contact />;
      default:
        return <div />;
    }
  }

  function topButton() {
    if (authed) {
      return (
        <button
          className={styles.optionsButton}
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            height: "50px",
            width: "50px",
          }}
          onClick={toggleOptions}
        >
          <span>
            <img
              style={{
                height: "30px",
                width: "30px",
                margin: "auto",
                display: "block",
              }}
              src="https://i.imgur.com/WkdBWph.png"
            ></img>
          </span>
        </button>
      );
    } else {
      return (
        <button
          className={styles.optionsButton}
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            height: "50px",
            width: "50px",
          }}
          onClick={() => {
            navigate("/auth");
          }}
        >
          <span>
            <img
              style={{
                height: "30px",
                width: "30px",
                margin: "auto",
                display: "block",
              }}
              src="https://i.imgur.com/wTBVvhm.png"
            ></img>
          </span>
        </button>
      );
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      {authed && <Profile uid={auth.currentUser.uid} />}
      {/*Options*/}
      {topButton()}
      {options && <Options apiKey={apiKey} handleSignOut={handleSignOut} />}
      {/*Main div*/}
      {/*Top area*/}
      <div
        style={{
          backgroundColor: "rgba(220,220,220,0)",
          display: "flex",
          flexDirection: "column",
          minWidth: "70vw",
          maxWidth: "90vw",
          maxHeight: "80vh",
          height: "1000px",
        }}
      >
        {/*Tabs div*/}
        <div style={{ height: "300px" }}></div>
        {/*Content div*/}
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: "1",
            height: "700px",
            marginBottom: "50px",
            alignItems: "center",
          }}
        >
          {getTab(tab)}
        </div>
      </div>
      {/*Bottom area*/}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.9)",
          minHeight: "600px",
        }}
      >
        <CreditTab
          authed={authed}
          userID={authed ? auth.currentUser.uid : ""}
        />
      </div>
    </div>
  );
};
