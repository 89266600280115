import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import styles from "../../css/styles.module.css";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const getCost = () => {
    switch (props.amount) {
      case 100:
        return 3;
        break;
      case 1000:
        return 20;
        break;
      default:
        return Math.round(props.amount * 0.016);
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/dashboard`,
      },
      redirect: "if_required",
    });

    if (error) {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setMessage("Payment status: " + paymentIntent.status);
      props.onFinished();
    } else {
      setMessage("Unexpected state");
    }

    setIsProcessing(false);
  };

  if (document.getElementById("payment-form"))
    document.getElementById("payment-form").scrollIntoView();

  return (
    <div>
      <form
        className={styles.stripeDiv}
        style={{}}
        id="payment-form"
        onSubmit={handleSubmit}
      >
        <button
          style={{ paddingBottom: "20px", backgroundColor: "rgba(0,0,0,0)" }}
          onClick={() => {
            props.onFinished();
          }}
        >
          X
        </button>
        <PaymentElement />
        {message && (
          <div
            style={{
              paddingTop: "10px",
              color: "red",
              fontWeight: "400",
              fontSize: "medium",
            }}
            id="payment-message"
          >
            {message}
          </div>
        )}
        <button
          className={styles.buttonConfirmPurchase}
          style={{ marginLeft: "auto", marginTop: "20px" }}
        >
          Pay ${getCost()} for {props.amount} credit
        </button>
      </form>
    </div>
  );
}
