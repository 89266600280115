import styles from "../../css/styles.module.css";
import ButtonDownload from "../buttons/buttonDownload";

export default function News() {
  const paragraphs = ["Starter project for Unreal Engine 5"];

  return (
    <div
      style={{
        maxWidth: "500px",
        display: "flex",
        flexDirection: "column",
        margin: "20px",
        alignItems: "flex-start",
        //backgroundColor: "black",
      }}
    >
      <div
        style={{
          paddingBottom: "20px",
          margin: "0",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <h1 style={{ paddingRight: "10px" }}>UG Engine</h1>
        <h3 style={{ marginTop: "auto" }}>v1</h3>
      </div>
      <div
        className={styles.paragraphs}
        style={{
          whiteSpace: "wrap",
          color: "rgb(250,250,250)",
          marginBottom: "20px",
          overflow: "scroll",
          flexShrink: "0",
          minHeight: "0",
        }}
      >
        {paragraphs.map((d, i) => {
          return (
            <div key={i}>
              <h2>{d}</h2>
              <br />
            </div>
          );
        })}
      </div>
      <ButtonDownload />
    </div>
  );
}
